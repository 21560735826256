import {
  InsureIQCoverageStatus,
  InsureIQLgmCommodityNames,
  InsureIQLgmCommodityTypeNames,
  InsureIQPolicyTypes,
  RmaLgmCommodityTypeCodes,
} from '@harvestiq/constants';
import { DbColumnDecimalNullable } from '@harvestiq/utils';
import { zDate, zDecimalNullable, zDecimalRequired } from '@harvestiq/zod';
import Decimal from 'decimal.js';
import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';
import {
  LgmCoverageDetails,
  lgmCoverageDetailsSchema,
} from './coverage-details';
import {
  LgmIndemnityDetails,
  lgmIndemnityDetailsSchema,
} from './lgm-indemnity-details';
import { dbSharedEndorsementSchema } from './shared';

/**
 * Represents an eventual LGM Endorsement table in the database.
 */
export type LgmEndorsementsTable = {
  id: GeneratedAlways<string>;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  commodity: InsureIQLgmCommodityNames;
  commodityType: InsureIQLgmCommodityTypeNames;
  commodityTypeCode: RmaLgmCommodityTypeCodes | null;
  createdBy: string | null;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  details: LgmCoverageDetails;
  lgmIndemnityDetails: LgmIndemnityDetails | null;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  reinsuranceYear: number;
  policyId: string | null;
  entityHistoryId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  type: InsureIQPolicyTypes;
  watchedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
  importSourceId: string | null;
  aipLgmPremiumKey: string | null;
  totalPremium: ColumnType<Decimal | null, number | null, number | null>;
  subsidyTotal: ColumnType<Decimal | null, number | null, number | null>;
  producerPremiumTotal: DbColumnDecimalNullable;

  // LGM only fields
  liveCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  feederCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  cornTargetBuPerHead: ColumnType<Decimal | null, number | null, number | null>;
  deductible: ColumnType<number | null, number | null, number | null>;
  targetMarketingsTotal: ColumnType<
    number | null,
    number | null,
    number | null
  >;
  expectedGrossMarginTotal: DbColumnDecimalNullable;
  grossMarginGuaranteeTotal: DbColumnDecimalNullable;
  lgmGrossMarginChangeTotal: DbColumnDecimalNullable;
  lgmActualGrossMarginTotal: DbColumnDecimalNullable;
  lgmActualIndemnity: DbColumnDecimalNullable;
  lgmNetActualIndemnity: DbColumnDecimalNullable;
  lgmProjectedGrossMarginTotal: DbColumnDecimalNullable;
  lgmProjectedIndemnity: DbColumnDecimalNullable;
  lgmNetProjectedIndemnity: DbColumnDecimalNullable;

  // LRP Only fields
  // TODO: Remove once we have two separate tables
  coverageLevel: never;
  coveragePricePerCwt: never;
  coveragePricePerHead: never;
  coveragePriceTotal: never;
  endorsementLength: never;
  headcount: never;
  producerPremiumPerCwt: never;
  producerPremiumPerHead: never;
  subsidyPerCwt: never;
  subsidyPerHead: never;
  subsidyRate: never;
  targetWeightQuantityCwt: never;
  totalPremiumPerCwt: never;
  totalPremiumPerHead: never;
};

export type DbLgmEndorsement = Selectable<LgmEndorsementsTable>;
export type DbLgmEndorsementInsert = Insertable<LgmEndorsementsTable>;
export type DbLgmEndorsementUpdate = Updateable<LgmEndorsementsTable>;

export const dbLgmEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLgmCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LGM),
  commodityType: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLgmCommodityTypeCodes).nullable(),
  details: lgmCoverageDetailsSchema,
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  cornTargetBuPerHead: zDecimalRequired().nullable(),
  importSourceId: z.string().nullable(),
  aipLgmPremiumKey: z.string().nullable(),
  lgmIndemnityDetails: lgmIndemnityDetailsSchema.nullable(),
  deductible: z.number().nullable(),
  totalPremium: zDecimalNullable(),
  subsidyTotal: zDecimalNullable(),
  producerPremiumTotal: zDecimalNullable(),
  targetMarketingsTotal: z.number().nullable(),
  expectedGrossMarginTotal: zDecimalNullable(),
  grossMarginGuaranteeTotal: zDecimalNullable(),
  lgmGrossMarginChangeTotal: zDecimalNullable(),
  lgmActualGrossMarginTotal: zDecimalNullable(),
  lgmActualIndemnity: zDecimalNullable(),
  lgmNetActualIndemnity: zDecimalNullable(),
  lgmProjectedGrossMarginTotal: zDecimalNullable(),
  lgmProjectedIndemnity: zDecimalNullable(),
  lgmNetProjectedIndemnity: zDecimalNullable(),
});

expectTypeOf<keyof DbLgmEndorsement>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementSchema>
>();

export const dbLgmEndorsementInsertSchema = dbLgmEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
    // new fields
    lgmIndemnityDetails: lgmIndemnityDetailsSchema.nullable(),
    deductible: z.number().nullable(),
    totalPremium: z.number().nullable(),
    subsidyTotal: z.number().nullable(),
    producerPremiumTotal: zDecimalNullable(),
    targetMarketingsTotal: z.number().nullable(),
    expectedGrossMarginTotal: zDecimalNullable(),
    grossMarginGuaranteeTotal: zDecimalNullable(),
    lgmGrossMarginChangeTotal: zDecimalNullable(),
    lgmActualGrossMarginTotal: zDecimalNullable(),
    lgmActualIndemnity: zDecimalNullable(),
    lgmNetActualIndemnity: zDecimalNullable(),
    lgmProjectedGrossMarginTotal: zDecimalNullable(),
    lgmProjectedIndemnity: zDecimalNullable(),
    lgmNetProjectedIndemnity: zDecimalNullable(),
  });

expectTypeOf<keyof DbLgmEndorsementInsert>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementInsertSchema>
>();

export const dbLgmEndorsementUpdateSchema =
  dbLgmEndorsementInsertSchema.partial();

expectTypeOf<keyof DbLgmEndorsementUpdate>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementUpdateSchema>
>();
