import { z } from 'zod';

import { InsureIQPolicyTypes } from '@harvestiq/constants';
import { dbAipSettingsSchema } from '../aip-settings/db-aip-settings';
import { safeUserSchema } from '../users/user';
import { lrpEndorsementSchema } from '../coverages/lrp-endorsement';
import { lgmEndorsementSchema } from '../coverages/lgm-endorsement';
import { policySchema } from '../policies/policy';

export const policyApplicationPdfSchema = z.object({
  type: z.nativeEnum(InsureIQPolicyTypes),
  policy: policySchema,
  agent: safeUserSchema.optional().nullable(),
  agencyInfo: dbAipSettingsSchema.optional(),
  lgmEndorsement: lgmEndorsementSchema.optional(),
  lrpEndorsement: lrpEndorsementSchema.optional(),
});
export type PolicyApplicationPdf = z.infer<typeof policyApplicationPdfSchema>;
