import {
  DbCalendarDateNullable,
  DbTimestampGenerated,
  DbTimestampNullable,
} from '@harvestiq/utils';
import { zCalendarDate, zTimestamp } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';

export type ImportSourcesTable = {
  id: GeneratedAlways<string>;
  filename: ColumnType<string>;
  reinsuranceYear: ColumnType<number>;
  aipFileProducedAt: DbCalendarDateNullable; // aip date can be the date included in the file (as agrisompo pattern) or the last modify date of the file (which we use to compose the filename for the other AIPs)
  finishedAt: DbTimestampNullable;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
};

export type DbImportSource = Selectable<ImportSourcesTable>;
export type DbImportSourceInsert = Insertable<ImportSourcesTable>;
export type DbImportSourceUpdate = Updateable<ImportSourcesTable>;

export const dbImportSourceSchema = z.object({
  id: z.string().uuid(),
  filename: z.string(),
  reinsuranceYear: z.number(),
  aipFileProducedAt: zCalendarDate().nullable(), // TODO: move to timestamp once changing the filename flow
  finishedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const dbImportSourceInsertSchema = dbImportSourceSchema
  .omit({
    id: true,
  })
  .partial()
  .extend({
    filename: z.string(),
    reinsuranceYear: z.number(),
    aipFileProducedAt: zCalendarDate().nullable().optional(), // Note: as it defaults to null it can be optional
    finishedAt: zTimestamp().nullable().optional(),
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
  });

export const dbImportSourceUpdateSchema = dbImportSourceInsertSchema.partial();

expectTypeOf<DbImportSource>().toMatchTypeOf<
  z.infer<typeof dbImportSourceSchema>
>();

type CreateImportSource = z.input<typeof dbImportSourceInsertSchema>;
expectTypeOf<keyof CreateImportSource>().toEqualTypeOf<
  keyof DbImportSourceInsert
>();
expectTypeOf<DbImportSourceInsert>().toMatchTypeOf<CreateImportSource>();

expectTypeOf<DbImportSourceUpdate>().toMatchTypeOf<
  z.infer<typeof dbImportSourceUpdateSchema>
>();
