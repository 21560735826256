export enum DataProvider {
  ADM = 1,
  Marex = 2,
  RJOBrien = 3,
  StoneX = 4,
  Cunningham = 5,
  Wedbush = 6,
  Lighthouse = 7,
  Dorman = 8,
}
