import { ChangeEvent, useMemo } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { usePrevious } from 'react-use'
import { parseFieldProps } from 'common'

import Field from '../Field'
import Heading from '../FormHeading'
import { useAppSettings } from '../../contexts'

export type FieldType = {
  groupClassNames?: string
  [key: string]: any
}

type FieldGroupProps = {
  fields: Record<string, FieldType>
  state: Record<string, any>
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  requiredFields?: string[] | string
  errors?: Record<string, string>
}

type ParsedFieldProps = {
  type: string
  className?: string
  CheckboxProps?: {
    required: boolean
  }
  [key: string]: any
}

export const FieldGroup = ({
  fields,
  state,
  onChange,
  requiredFields: requiredFieldsProp = [],
  errors = {},
}: FieldGroupProps) => {
  const { isMobile } = useAppSettings()
  const prevState = usePrevious(state)
  const fieldsArr = Object.entries(fields)
  const fieldCount = fieldsArr.length

  const groupClassNameField = fieldsArr.find(
    ([_, field]) => field.groupClassNames,
  )
  const groupClassNames = groupClassNameField
    ? groupClassNameField[1].groupClassNames
    : 'flex flex-1 gap-2'

  let layoutClassName: string | null
  // Let the group class name override if present
  if (groupClassNameField) {
    layoutClassName = null
  } else if (isMobile && fieldCount > 1) {
    layoutClassName = 'flex-col'
  } else {
    layoutClassName = 'flex-row'
  }

  const requiredFields = useMemo(
    () =>
      typeof requiredFieldsProp === 'string'
        ? requiredFieldsProp.split('|')
        : requiredFieldsProp,
    [requiredFieldsProp],
  )

  return (
    <div className={classNames(layoutClassName, groupClassNames)}>
      {fieldsArr.map(([name, fieldType]) => {
        if (!fieldType) return null

        const flexBasis = Math.round(100 / fieldsArr.length)
        const isRequired = requiredFields.includes(name)

        const parsedFieldProps = parseFieldProps({
          field: fieldType,
          state,
          prevState: prevState ?? {},
        })

        if (!parsedFieldProps) return null

        const { type, className, ...additionalFieldProps } =
          parsedFieldProps as ParsedFieldProps

        const defaultFieldProps = {
          type,
          name,
          value: state[name],
          required: isRequired,
          fullWidth: false,
          ...additionalFieldProps,
        }

        let fieldProps = defaultFieldProps

        if (!['checkbox', 'slider'].includes(type)) {
          fieldProps.fullWidth = fieldCount > 1
        }

        if (type === 'checkbox') {
          fieldProps = {
            ...fieldProps,
            CheckboxProps: {
              required: isRequired,
            },
          }
        }
        if (type === 'heading') {
          return (
            <Heading
              key={`key-${name}`}
              name={name}
              containerClass={additionalFieldProps.containerClass || ''}
              label={additionalFieldProps.label}
              {...additionalFieldProps}
            >
              {additionalFieldProps.children || null}
            </Heading>
          )
        }

        // Remove groupClassNames to avoid passing to children and causing errors
        if ('groupClassNames' in fieldProps) delete fieldProps.groupClassNames

        return (
          <Box
            data-testid={name}
            key={`key-${name}`}
            className={
              className ??
              `flex gap-2 flex-basis-${parseFloat(flexBasis.toFixed(2))}`
            }
          >
            <Field
              error={errors[name]}
              className="width-100"
              onChange={onChange}
              {...fieldProps}
            />
          </Box>
        )
      })}
    </div>
  )
}

export default FieldGroup
