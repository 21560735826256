export enum InsureIQCoverageStatus {
  IN_REVIEW = 'In Review',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  WATCHED = 'Watched',
  CANCELED = 'Canceled', // Note: Canceled should only be set from imported coverages for now
}

export const InsureIQStatusStyles = {
  [InsureIQCoverageStatus.ACTIVE]: 'success',
  [InsureIQCoverageStatus.IN_REVIEW]: 'secondary',
  [InsureIQCoverageStatus.EXPIRED]: 'warning',
};
