import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Typography } from '@mui/material'

import { getRoleUsers } from '../../../http'
import { Select } from '../Select'

export const SelectAgents = ({ value, ...props }) => {
  const { data: agents = [], isLoading } = useQuery(
    ['getAgents'],
    async () => {
      const response = await getRoleUsers({
        id: 'agent',
        attributes: ['id', 'firstName', 'lastName', 'fullName', 'email'],
        isPaginated: false,
      })
      return response?.users || []
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )

  const options = useMemo(() => {
    const agentOptions = [{ label: 'None', value: '' }]
    agents.forEach(({ fullName, email, id }) => {
      agentOptions.push({
        label: (
          <div className="flex flex-col text-left">
            <Typography className="bold">{fullName}</Typography>
            <Typography fontSize="small">{email}</Typography>
          </div>
        ),
        value: id,
      })
    })
    return agentOptions
  }, [agents])

  return (
    <Select
      name="agent"
      value={value}
      options={options}
      isLoading={isLoading}
      SelectProps={{
        displayEmpty: true,
        renderValue: rValue => {
          if (!rValue) {
            return options[0].label
          }
          return options.find(option => option.value === rValue)?.label || ''
        },
      }}
      {...props}
    />
  )
}
