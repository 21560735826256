/* eslint-disable no-param-reassign */
import { TextField, TextFieldProps } from '@mui/material'
import React, { useCallback } from 'react'
import { noop } from 'common'

type NumberInputProps = TextFieldProps & {
  onChange?: (_: React.ChangeEvent<HTMLInputElement>) => void
  showStepper?: boolean
  allowDecimals?: boolean
  decimalPlaces?: number
  allowNegatives?: boolean
  min?: number | null
  max?: number | null
  allowEmpty?: boolean
}

export const NumberInput = ({
  onChange = noop,
  showStepper = false,
  allowDecimals = false,
  decimalPlaces = 2,
  allowNegatives = true,
  type = 'number',
  min = null,
  max = null,
  allowEmpty = false,
  ...props
}: NumberInputProps) => {
  // Mui number input not triggering onChange for comma and dot

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const blacklist = allowDecimals ? [','] : ['.', ',']
      if (blacklist.includes(e.key)) {
        e.preventDefault()
      }
    },
    [allowDecimals],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // if value is provided but not a number do nothing
      const { value } = event.target
      if (!allowNegatives && (value === '-' || value.indexOf('-') !== -1)) {
        return event.preventDefault()
      }

      // if decimal is provided then round to decimalPlaces
      if (!!+value && allowDecimals && decimalPlaces) {
        const decimalPlacesStr = event.target.value.toString().split('.')[1]
        if (decimalPlacesStr?.length > decimalPlaces) {
          return null
        }
      }

      onChange(event)
    },
    [allowDecimals, allowNegatives, decimalPlaces, onChange],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target

      // if negative is not allowed, make it absolute
      if (!allowNegatives && parseFloat(value) < 0) {
        return null
      }

      // Clamp the value between min and max if present preserving string type
      if (min !== null && min !== undefined && parseFloat(value) < min) {
        event.target.value = Math.max(parseFloat(value), min).toString()
      }
      if (max !== null && max !== undefined && parseFloat(value) > max) {
        event.target.value = Math.min(parseFloat(value), max).toString()
      }

      if (!allowEmpty && event.target.value === '') {
        event.target.value = '0'
      }
      onChange(event)
    },
    [allowNegatives, min, max, onChange],
  )

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select()
    },
    [],
  )

  const inputType = showStepper ? 'number' : type

  return (
    <TextField
      type={inputType}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...props}
    />
  )
}
