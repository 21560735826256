import { z } from 'zod';
import { recipientSchema } from '../users/recipients';
import { LivestockInsuranceTypes } from '@harvestiq/constants';
import { zCoercedBoolean, zIsoDate } from '@harvestiq/zod';
import { lgmDetailsMarketingMonthsSchema } from './coverage-details';

const coverageReminderLgmMarketingMonthSchema =
  lgmDetailsMarketingMonthsSchema.extend({
    formattedMarketingMonth: z.string(), // formatted month and year
    numberOfHead: z.number(),
  });

export const coverageReminderDataSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(LivestockInsuranceTypes.LRP),
    coverageId: z.string(),
    salesEffectiveDate: zIsoDate(),
    assignedAgent: z
      .object({
        email: z.string(),
        name: z.string(),
        phone: z.string().optional(),
      })
      .optional(),
    coverageLevel: z.number(), // ?decimal?
    coveragePricePerCwt: z.number(), // ?decimal?
    coveragePricePerHead: z.number(), // ?decimal?
    targetWeight: z.coerce.number(),
    endDate: zIsoDate(),
    endorsementLength: z.coerce.number(), // can be string or number in DB
    numberOfHead: z.coerce.number(), // can be string or number in DB
    commodity: z.string(),
    commodityType: z.string(),
    insuredName: z.string(),
  }),
  z.object({
    type: z.literal(LivestockInsuranceTypes.LGM),
    coverageId: z.string(),
    salesEffectiveDate: zIsoDate(),
    assignedAgent: z
      .object({
        email: z.string(),
        name: z.string(),
        phone: z.string().optional(),
      })
      .optional(),
    endDate: zIsoDate(),
    deductible: z.number(),
    marketingMonths: z.array(coverageReminderLgmMarketingMonthSchema),
    commodity: z.string(),
    commodityType: z.string(),
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    insuredName: z.string(),
  }),
]);

export type CoverageReminderData = z.infer<typeof coverageReminderDataSchema>;

export const coverageReminderApiResponseSchema = z.object({
  coverages: z
    .object({
      to: z.array(recipientSchema),
      cc: z.array(recipientSchema).optional(),
      coverageReminderData: coverageReminderDataSchema,
    })
    .array(),
});

export type CoverageReminderApiResponse = z.infer<
  typeof coverageReminderApiResponseSchema
>;

export const coverageReminderApiRequestSchema = z.object({
  type: z.nativeEnum(LivestockInsuranceTypes),
  sendPreview: z.string().optional(),
  currentDay: zIsoDate().optional(),
});

export type CoverageReminderApiRequest = z.infer<
  typeof coverageReminderApiRequestSchema
>;
