import { zDecimalNullable } from '@harvestiq/zod';
import { z } from 'zod';

export const lgmIndemnityDetailsByMarketingMonthsSchema = z.object({
  grossMarginGuarantee: z.coerce.number(),
  marketingMonth: z.string(),
  premium: z.coerce.number(),
  subsidy: z.coerce.number(),
  projectedGrossMargin: z.number().optional(),
  projectedGrossMarginChange: z.number().optional(),
  actualGrossMargin: z.number().optional(),
  actualGrossMarginChange: z.number().optional(),
  allocatedActualIndemnity: zDecimalNullable().optional(),
  allocatedNetActualIndemnity: zDecimalNullable().optional(),
  allocatedProjectedIndemnity: zDecimalNullable().optional(),
  allocatedNetProjectedIndemnity: zDecimalNullable().optional(),
});
export type LgmIndemnityDetailsByMarketingMonths = z.infer<
  typeof lgmIndemnityDetailsByMarketingMonthsSchema
>;

export const lgmIndemnityDetailsSchema = z.object({
  marketingMonths: z.array(lgmIndemnityDetailsByMarketingMonthsSchema),
});
export type LgmIndemnityDetails = z.infer<typeof lgmIndemnityDetailsSchema>;
