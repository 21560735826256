import { stringify } from 'qs'
import { apiRoot } from 'common'

const routePatterns = [
  /.+media\/(.+)\/(url|download|signedUrl)/,
  /.+policies\/(.+)\/(application)/,
  /.+login$/,
]
const getHandlerKey = (
  url: string,
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
) => {
  const matches = routePatterns.reduce((acc: string[], pattern) => {
    const match = url.match(pattern)

    if (match) {
      acc.push(match[1])
    }
    return acc
  }, [])

  url = matches.reduce((acc, match) => acc.replace(match, ''), url)

  const apiKey = url
    .replace(new RegExp(`^(.*?)${apiRoot}/`), '')
    .replace(/^[a-z]/g, m => m.toUpperCase())
    .replace(/(\/[a-z])/g, m => m.slice(1).toUpperCase())
    .replace(/(\/)/g, m => m.slice(1).toUpperCase())

  return `${method?.toLowerCase() ?? 'get'}${apiKey}`
}

type ResponseHandler = (res: Response) => Promise<any>

const handleRawErrors: ResponseHandler = async (res: Response) => {
  console.debug('Handling raw errors for response:', res)
  const textBody = await res.clone().text()
  console.debug('Response text body:', textBody)

  const { error, status, ...data } = await res.json().catch(() => {
    console.debug('Failed to parse JSON, attempting to parse as HTML')
    const parser = new DOMParser()
    const doc = parser.parseFromString(textBody, 'text/html')
    const parsedError = doc.body.innerText.trim()
    console.debug('Parsed HTML error:', parsedError)
    return {
      status: res.status,
      data: null,
      error: parsedError,
    }
  })

  console.debug('Parsed response:', { status, data, error })

  return {
    status: status || res.status,
    data: Object.keys(data).length ? data : null,
    error,
  }
}

const responseHandlers: Record<string, ResponseHandler> = {
  getMediaDownload: async (res: Response) => {
    let filename = res.headers.get('x-filename')
    if (!filename) {
      filename = res.headers.get('content-disposition')
      if (filename) {
        filename = filename.split('filename=')[1]
      } else {
        const match = res.url.match(/.+media\/(.*)\/download/)
        filename = match?.[1] ?? 'download'
      }
    }

    const blob = await res.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    if (filename) {
      link.download = decodeURIComponent(filename)
    }
    link.click()
  },
  getMediaUrl: async (res: Response) => {
    const url = await res.text()
    window.open(url)
  },
  getPoliciesApplicationUrl(res: Response) {
    return this.getMediaUrl(res)
  },
  postConfirm: handleRawErrors,
  postLogin: handleRawErrors,
}

const defaultHeaders = Object.freeze({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-client-id': 'portal-webapp',
})

const http = {
  getHeaders: (additionalHeaders: Record<string, any> = {}) => ({
    ...defaultHeaders,
    ...additionalHeaders,
  }),
}

const defaultOptions: {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  credentials?: 'include'
  headers?: Record<string, any>
  body?: string | FormData // stringified JSON or FormData
  handler?: ResponseHandler | null
} = { method: 'GET', credentials: 'include', headers: http.getHeaders() }

type FetcherResponse<T> = {
  url: string
  status: number
  data: T
  errors: any
  error: any
}
export async function fetcher<T = unknown>(
  requestUrl: URL | string,
  { handler, ...optionsParam } = defaultOptions,
): Promise<FetcherResponse<T>> {
  const options = { ...defaultOptions, ...optionsParam }
  console.debug(`Fetcher options for url "${requestUrl}":`, options)

  const res = await fetch(requestUrl, options)
  console.debug('Fetch response:', res)

  const { url, status } = res
  const handlerKey = getHandlerKey(url, options.method)

  if (responseHandlers[handlerKey]) {
    console.debug('Using response handler for key:', handlerKey)
    return responseHandlers[handlerKey](res)
  }

  if (handler) {
    console.debug('Using custom handler')
    return handler(res)
  }

  if (status === 0) {
    console.info('User network error')
  }

  let jsonErrors: unknown
  let jsonError: unknown
  let restData: unknown = null

  try {
    const { errors, error, ...jsonData } = await res.json()
    jsonErrors = errors
    jsonError = error
    restData = jsonData
    console.debug('Parsed JSON response:', jsonData)
  } catch (e) {
    console.error('Error parsing JSON response:', e)
  }

  return {
    url,
    status,
    data: (Object.keys(restData as any).length ? restData : null) as T,
    errors: jsonErrors || (jsonError && [jsonError]),
    error: jsonError || (jsonErrors && (jsonErrors as any)[0]),
  }
}

export const api = {
  urlString: (path: string) => {
    const hostname = window.location.origin
    return `${hostname}${apiRoot}${path}`
  },
  assign: (path: string, params: Record<string, any>) => {
    let urlString = api.urlString(path)
    if (params) {
      urlString += `?${stringify(params)}`
    }
    window.location.assign(urlString)
  },
  get: <T = unknown>(
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
  ) => {
    let urlString = api.urlString(path)
    if (params && !!Object.keys(params).length) {
      urlString += `?${stringify(params)}`
    }
    return fetcher<T>(urlString, {
      method: 'GET',
      headers: http.getHeaders(headers),
    })
  },
  post: <T = unknown>(
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
    handler: ResponseHandler | null = null,
  ) => {
    const urlString = api.urlString(path)
    return fetcher<T>(urlString, {
      method: 'POST',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
      handler,
    })
  },
  upload: (path: string, file: string | Blob) => {
    const urlString = api.urlString(path)
    const formData = new FormData()
    formData.append('files', file)

    return fetcher(urlString, {
      method: 'POST',
      body: formData,
    })
  },
  download: (
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
  ) => {
    let urlString = api.urlString(path)
    if (params) {
      urlString += `?${stringify(params)}`
    }
    return fetcher(urlString, {
      method: 'GET',
      headers: http.getHeaders(headers),
      handler: responseHandlers.getMediaDownload,
    })
  },
  patch: <T = unknown>(
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
  ) => {
    const urlString = api.urlString(path)
    return fetcher<T>(urlString, {
      method: 'PATCH',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
  put: <T = unknown>(
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
  ) => {
    const urlString = api.urlString(path)
    return fetcher<T>(urlString, {
      method: 'PUT',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
  delete: <T = unknown>(
    path: string,
    params?: Record<string, any>,
    headers: Record<string, any> = {},
  ) => {
    const urlString = api.urlString(path)
    return fetcher<T>(urlString, {
      method: 'DELETE',
      headers: http.getHeaders(headers),
      body: JSON.stringify(params),
    })
  },
}
