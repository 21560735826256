import { z } from 'zod';

export const updateUserAgentRequestSchema = z.object({
  agentId: z.union([z.string().uuid(), z.string().length(0)]).nullable(),
});
export type UpdateUserAgentRequest = z.infer<
  typeof updateUserAgentRequestSchema
>;

export const updateUserAgentResponseSchema = z.object({
  data: z.array(
    z.object({
      agentId: z.string().uuid().nullable(),
      // TODO: Fill this out if/when response is needed
    })
  ),
});
export type UpdateUserAgentResponse = z.infer<
  typeof updateUserAgentResponseSchema
>;
