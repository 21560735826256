import { api } from '../utils'
import { GetMediaByKeySignedUrlResponse } from '@harvestiq/iiq/common/media/get-media-by-key-and-action-endpoint'

export const uploadFile = (file: any) => api.upload('/media', file)

export const openMedia = (filename: string) => api.get(`/media/${filename}/url`)
export const downloadMedia = (filename: string) =>
  api.get(`/media/${filename}/download`)

export const getMediaSignedUrl = ({ key }: { key: string }) =>
  api.get<GetMediaByKeySignedUrlResponse>(`/media/${key}/signedUrl`)
