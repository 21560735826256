import { z } from 'zod';

export const placeDetailsSchema = z.object({
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  city: z.string().optional(),
  county: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  addressNumber: z.string().optional(),
});

export type PlaceDetails = z.infer<typeof placeDetailsSchema>;

export const getPlaceDetailsRequestSchema = z.object({
  placeId: z.string().trim().min(1, 'Required'),
});

export type GetPlaceDetailsRequest = z.infer<
  typeof getPlaceDetailsRequestSchema
>;

export const getPlaceDetailsResponseSchema = z.object({
  details: placeDetailsSchema,
});

export type GetPlaceDetailsResponse = z.infer<
  typeof getPlaceDetailsResponseSchema
>;
